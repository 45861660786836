
export default class CommonUtils{

    static booleanify(value: any): boolean {
        const truthy: string[] = [
            'true',
            'True',
            '1'
        ]
      
        return truthy.includes(value)
    }

    static getToday(): string {
        const hoy = new Date();
        const ano = hoy.getFullYear();
        const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses son de 0 a 11, por eso se suma 1
        const dia = String(hoy.getDate()).padStart(2, '0');

        const fechaFormateada = `${ano}-${mes}-${dia}`;
        return fechaFormateada
    }

    static dateToDatabaseFormat(date: string): string {
        const fechaEntrada = new Date(date);
        const ano = fechaEntrada.getFullYear();
        const mes = String(fechaEntrada.getMonth() + 1).padStart(2, '0'); // Se suma 1 porque los meses son de 0 a 11
        const dia = String(fechaEntrada.getDate()).padStart(2, '0');

        const fechaFormateada = `${ano}-${mes}-${dia}`;
        return fechaFormateada;
    }
}

