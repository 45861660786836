import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardService } from './core/services/auth-guard.service';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot([
        { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
        { path: 'landing', loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule) },
        { path: 'landing', loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule) },
        { path: '', loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule) },
        {
            path: 'dashboard', component: LayoutComponent,
            canActivate: [AuthGuardService],
            children: [
                { path: '', canActivate: [AuthGuardService], loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule) },
                { path: 'categorias', canActivate: [AuthGuardService], loadChildren: () => import('./features/categorias/categorias.module').then(m => m.CategoriasModule) },
                { path: 'productos', canActivate: [AuthGuardService], loadChildren: () => import('./features/productos/productos.module').then(m => m.ProductosModule) },
                { path: 'pedidos', canActivate: [AuthGuardService], loadChildren: () => import('./features/pedidos/pedidos.module').then(m => m.PedidosModule) },
                { path: 'menu-diario', canActivate: [AuthGuardService], loadChildren: () => import('./features/menu-dia/menu-dia.module').then(m => m.MenuDiaModule) },
                /*{ path: 'perfil', canActivate: [AuthGuardService], loadChildren: () => import('./features/perfil/perfil.module').then(m => m.PerfilModule) },
                { path: 'comunicaciones', canActivate: [AuthGuardService], loadChildren: () => import('./features/comunicaciones/comunicaciones.module').then(m => m.ComunicacionesModule) },
                { path: 'comunicacion/:id', canActivate: [AuthGuardService],loadChildren: () => import('./features/comunicacion/comunicacion.module').then(m => m.ComunicacionModule) },*/
            ]
        },
        { path: '**', redirectTo: '/notfound' },
    ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
],
exports: [RouterModule]
})
export class AppRoutingModule { }
