<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/img/logos/logo.png" style="height: 3.5rem !important;" alt="{{company}}">
    </a>

    <button #menubutton class="p-link layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <div class="">
            <!--<span class="mr-2" style="display:block !important;">{{labelEmail}}</span>-->
            <button class="p-link layout-topbar-button" (click)="menu.toggle($event)">
                <i class="pi pi-user"></i>
            </button>
            <p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
        </div>
    </div>
</div>