import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../services/layout.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  company: string = environment.company;

  labelEmail: string | null = '';

  constructor(public layoutService: LayoutService, private router: Router) { }

  ngOnInit(): void {
    this.labelEmail = localStorage.getItem("email");
    
    this.items = [
        {   
            id: 'perfil',
            target: 'perfil',
            label: 'Perfil',
            icon: 'pi pi-fw pi-user',
            command: () => {
              this.showProfile();
            } 
        },
        {
            separator: true
        },
        {
            id: 'salir',
            target: 'salir',
            label: 'Salir',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
              this.logout();
            } 
        }
    ];
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  showProfile(): void {
    this.router.navigate(['/dashboard/perfil']);
  }
}
